import { useState, useEffect } from "react";
import Lottie from "lottie-react";
import FireworkAnimation from "./assets/animate/firework-animation.json";
import ScratchAnimate from "./assets/animate/scratch-animation.json";

import Coin from "./assets/images/coin.svg";
import Spark from "./assets/images/spark.svg";
import "./LottieScratch.css";
import parse from 'html-react-parser';

import { CryptoHandler } from './cryptoHandler';

function App() {
  const [showScratch, setShowScratch] = useState(false);
  const [showFirework, setShowFirework] = useState(false);
  const [isPrizeRevealed, setIsPrizeRevealed] = useState(false); // state to track if prize is revealed
  const [couponInfo, setCouponInfo] = useState({}); // state to hold coupon information
  const [isLoading, setIsLoading] = useState(true); // state to track loading status

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedRewardId = urlParams.get('RewardId');
    const pathName = window.location.pathname;
  
    console.log('Encrypted RewardId:', encryptedRewardId);
    console.log('Path Name:', pathName);
  
    if (encryptedRewardId) {
      try {
        const cryptoHandler = new CryptoHandler();
        const decodedRewardId = decodeURIComponent(encryptedRewardId);
        console.log('Decoded RewardId:', decodedRewardId);
  
        // 解密操作
        const decryptedRewardId = cryptoHandler.decrypt(decodedRewardId);
        console.log('Decrypted RewardId:', decryptedRewardId);
  
        if (decryptedRewardId === "0") {
          console.log('RewardId is 0, skipping API call');
          
          if (pathName.includes('/IC5')) {
            setCouponInfo({
              eventSubtitle: "歡慶春節抽折抵卷",
              eventName: "iRent刮刮樂",
              prizeName: "銘謝惠顧",
              description: "",
            });
          } else if (pathName.includes('/MC5')) {
            setCouponInfo({
              eventSubtitle: "2025迎春好禮抽",
              eventName: "和運租車 刮刮樂",
              prizeName: "銘謝惠顧",
              description: "",
            });
          } else if (pathName.includes('/ID5')) {
            setCouponInfo({
              eventSubtitle: "iRent刮刮樂 最大獎600分鐘 !",
              eventName: "張張有獎 !",
              prizeName: "銘謝惠顧",
              description: "",
            });
          } else if (pathName.includes('/IE5')) {
            setCouponInfo({
              eventSubtitle: "iRent刮刮樂 最大獎600分鐘 !",
              eventName: "張張有獎 !",
              prizeName: "銘謝惠顧",
              description: "",
            });
          } else if (pathName.includes('/IF5')) {
            setCouponInfo({
              eventSubtitle: "iRent刮刮樂 最大獎600分鐘 !",
              eventName: "張張有獎 !",
              prizeName: "銘謝惠顧",
              description: "",
            });
          }
          setIsLoading(false);
        } else if (decryptedRewardId !== "0" && isValidBase64(decodedRewardId)) {
          fetch(process.env.REACT_APP_API_URL, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ RewardId: decryptedRewardId }),
          })
            .then(response => response.json())
            .then(data => {
              if (data.success) {
                const eventId = data.data.event_id;
                
                if (pathName.includes('/IC5') && eventId === 'IC5') {
                  setCouponInfo({
                    eventSubtitle: data.data.event_subtitle,
                    eventName: data.data.event_name,
                    prizeName: data.data.prize_name,
                    description: data.data.desc,
                  });
                } else if (pathName.includes('/MC5') && eventId === 'MC5') {
                  setCouponInfo({
                    eventSubtitle: data.data.event_subtitle,
                    eventName: data.data.event_name,
                    prizeName: data.data.prize_name,
                    description: data.data.desc,
                  });
                } else if (pathName.includes('/ID5') && eventId === 'ID5') {
                  setCouponInfo({
                    eventSubtitle: data.data.event_subtitle,
                    eventName: data.data.event_name,
                    prizeName: data.data.prize_name,
                    description: data.data.desc,
                  });
                } else if (pathName.includes('/IE5') && eventId === 'IE5') {
                  setCouponInfo({
                    eventSubtitle: data.data.event_subtitle,
                    eventName: data.data.event_name,
                    prizeName: data.data.prize_name,
                    description: data.data.desc,
                  });
                } else if (pathName.includes('/IF5') && eventId === 'IF5') {
                  setCouponInfo({
                    eventSubtitle: data.data.event_subtitle,
                    eventName: data.data.event_name,
                    prizeName: data.data.prize_name,
                    description: data.data.desc,
                  });
                }
              } else {
                console.error('Error in response:', data.errorcode, data.msg);
              }
            })
            .catch(error => {
              console.error('Error fetching prize data:', error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          console.error('RewardId parameter is not a valid Base64 string');
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error during decryption process:', error);
        setIsLoading(false);
      }
    } else {
      if (pathName.includes('/IC5')) {
        setCouponInfo({
          eventSubtitle: "歡慶春節抽折抵卷",
          eventName: "iRent刮刮樂",
          prizeName: "",
          description: "",
        });
      } else if (pathName.includes('/MC5')) {
        setCouponInfo({
          eventSubtitle: "2025迎春好禮抽",
          eventName: "和運租車 刮刮樂",
          prizeName: "",
          description: "",
        });
      } else if (pathName.includes('/ID5')) {
        setCouponInfo({
          eventSubtitle: "iRent刮刮樂 最大獎600分鐘 !",
          eventName: "張張有獎 !",
          prizeName: "",
          description: "",
        });
      } else if (pathName.includes('/IE5')) {
        setCouponInfo({
          eventSubtitle: "iRent刮刮樂 最大獎600分鐘 !",
          eventName: "張張有獎 !",
          prizeName: "",
          description: "",
        });
      } else if (pathName.includes('/IF5')) {
        setCouponInfo({
          eventSubtitle: "iRent刮刮樂 最大獎600分鐘 !",
          eventName: "張張有獎 !",
          prizeName: "",
          description: "",
        });
      }
      setIsLoading(false);
    }
  }, []);
  
  
  const isValidBase64 = (str) => {
    if (str === "0") return true; 
    const base64Regex = /^[A-Za-z0-9+/=]+$/;
    return str.length % 4 === 0 && base64Regex.test(str) && atob(str) !== undefined;
  };
  
  const isHTML = (str) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  };
  const handleScratch = () => {
    setShowScratch(true);
    setIsPrizeRevealed(true);
  };

  const scratchAnimationStyles = {
    position: 'absolute',
    top: '58%',
    left: '50%',
    width: '260px',
    transform: 'translate(-50%, -58%)',
    display: showFirework ? "none" : "flex",
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
    zIndex: 3,
  };

  const coinArray = Array.from({ length: 6 });
  const sparkArray = Array.from({ length: 7 });

  return (
    <div className="container">
      <div className="scratch-card">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {coinArray.map((_, index) => (
              <img src={Coin} key={index} className={`coin coin${index + 1}`} alt="coin" />
            ))}
            {sparkArray.map((_, index) => (
              <img src={Spark} key={index} className={`spark spark${index + 1}`} alt="spark" />
            ))}
            <div>
              <div className="scratch-card__content">
                <div className="scratch-card__sub-title">{couponInfo.eventSubtitle}</div>
                <div className="title">{couponInfo.eventName}</div>
              </div>
              <div className="scratch-card__area">
                {showScratch ? (
                  <Lottie
                    style={scratchAnimationStyles}
                    animationData={ScratchAnimate}
                    loop={false}
                    autoPlay={true}
                    onComplete={() => setShowFirework(true)}
                  />
                ) : (
                  <div className="overlay" onClick={handleScratch}>
                    <span className="scratch-card__mask-text">點我刮開!</span>
                  </div>
                )}
                <div className="scratch-card__message">
                  <div
                    className="scratch-card__coupon-content"
                    style={couponInfo.prizeName === "銘謝惠顧" ? { fontSize: '20px' } : {}}
                  >
                    {isPrizeRevealed && <span>{couponInfo.prizeName}</span>}
                  </div>
                  <hr />
                  <div className="scratch-card__coupon-item">
                    {isPrizeRevealed &&
                      (typeof couponInfo.description === "string" &&
                      isHTML(couponInfo.description) ? (
                        parse(couponInfo.description) // 安全地解析並渲染 HTML
                      ) : (
                        <span>{couponInfo.description}</span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {showFirework && (
              <Lottie
                className="scratch-card__firework-animation"
                animationData={FireworkAnimation}
                loop={false}
                autoPlay={true}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default App;