import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.jsx';
import CouponApp from './Coupon_App.jsx';
import Home from './home.jsx';
import reportWebVitals from './reportWebVitals.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

// 判斷 URL 是否包含 "MC5"、"IC5"、"ID5"、"IE5" 或 "IF5"
const isMC5 = window.location.pathname.includes('MC5');
const isIC5 = window.location.pathname.includes('IC5');
const isID5 = window.location.pathname.includes('ID5');
const isIE5 = window.location.pathname.includes('IE5');
const isIF5 = window.location.pathname.includes('IF5');

// 根據 URL 設置 HTML 標題
if (isMC5) {
  document.title = '和運租車 刮刮樂';
} else if (isIC5 || isID5 || isIE5 || isIF5) {
  document.title = 'iRent刮刮樂';
} else {
  document.title = 'Hi-Ms抽獎';
}

// 根據 URL 渲染不同的組件
root.render(
  <React.StrictMode>
    {isMC5 ? <CouponApp /> : (isIC5 || isID5 || isIE5 || isIF5) ? <App /> : <Home />}
  </React.StrictMode>
);

reportWebVitals();
